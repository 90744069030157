import logo from './logo.svg';
import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";


// Import Routes
import Root from "./routes/root";
import ErrorPage from "./routes/error-page";
import Impressum from "./routes/impressum";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />
  },
  {
    path: "/Impressum",
    element: <Root />,
    errorElement: <ErrorPage />
  }
]);

function App() {
  return (
    ReactDOM.createRoot(document.getElementById("root")).render(
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    )
  )
}

export default App;
